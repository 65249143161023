import MainLayout from '@/components/layouts/mainlayout';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';

export default function HomePage() {
  return (
    <MainLayout>
      <Typography
        variant="h1"
        gutterBottom
        align="left"
        fontWeight={700}
        fontSize="28px"
        sx={{ml: '24px', mb: '24px'}}
      >
        Bem-vindo de volta! 👋🏽
      </Typography>
      <Grid container spacing="24px" sx={{height: '340px'}}>
        <Grid item xs={4}>
          <Card sx={{height: '100%'}}>
            <CardHeader title="Cartão 1" />
            <CardContent></CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{height: '100%'}}>
            <CardHeader title="Cartão 2" />
            <CardContent></CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{height: '100%'}}>
            <CardHeader title="Cartão 3" />
            <CardContent></CardContent>
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  );
}
